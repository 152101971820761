import React from 'react';

import { lazyLoading } from '../../../utils/lazyLoading';
import { PostStats } from '../Stats';

import style from './PostCard.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export interface IPostCard {
  id?: string;
  codeinjection_styles?: any;
  title: string;
  excerpt: string;
  custom_excerpt: string | null;
  subTitle: string;
  html: string;
  feature_image?: string;
  slug: string;
  tags: {
    slug: string;
    name: string;
  }[];
  published_at: string;
  updated_at: string;
  primary_author: {
    slug: string;
    name: string;
    profile_image: string;
    bio: string;
  };
}

interface IProps {
  className?: string;
  post: IPostCard;
  imageClassName?: string;
  imageHeight?: number;
  isExcerptShown?: boolean;
  isLazy?: boolean;
}

export const PostCard = ({
  className,
  post,
  imageClassName,
  imageHeight,
  isExcerptShown,
  isLazy = true,
}: IProps) => {
  const { slug, feature_image, tags, title, excerpt, published_at, primary_author } = post;
  const url = `/${slug}/`;

  React.useEffect(() => {
    lazyLoading();
  }, []);

  return (
    <Link to={url} className={cn(className, style.base)} aria-label={title}>
      <div
        className={cn(style.imageWrapper, { [style.hasTag]: tags && tags[0] && tags[0].name })}
        data-tag={tags && tags[0] && tags[0].name}
      >
        <img
          {...(isLazy
            ? {
                'data-src': feature_image,
                'data-srcset': feature_image,
              }
            : {
                src: feature_image,
                srcSet: feature_image,
              })}
          className={cn(isLazy ? 'lazy' : '', style.image, imageClassName)}
          alt="Image"
          aria-label={title}
        />
      </div>
      <div className={style.content}>
        <div className={cn(style.headerContent, { [style.isExcerptShown]: isExcerptShown })}>
          <h2 className={style.title}>{title}</h2>
          {isExcerptShown ? <p>{excerpt}</p> : null}
        </div>
        <div className={style.postStats}>
          <PostStats
            publishedAt={published_at}
            isReadingTimeShown
            post={post}
            author={primary_author}
          />
        </div>
      </div>
    </Link>
  );
};
