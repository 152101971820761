import React from 'react';

import SignUpBanner from '../components/Banners/SignUp';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import { PostGrid } from '../components/Post/Grid';
import PostsIcon from '../images/Posts';

import style from '../styles/pages/tag.scss';

import { graphql } from 'gatsby';

export interface ITag {
  name: string;
  description?: string;
  feature_image?: string;
  slug: string;
}

interface ITagProps {
  data: {
    ghostTag: ITag;
    allGhostPost: any;
    allGhostPage: any;
    allFeaturedTagColorsJson: {
      edges: {
        node: {
          tag: string;
          color: string;
        };
      }[];
    };
  };
  location: {
    pathname: string;
  };
  pageContext?: any;
}

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({ data, location, pageContext }: ITagProps) => {
  const { ghostTag, allGhostPost, allGhostPage, allFeaturedTagColorsJson } = data;

  const { name, description, feature_image, slug } = ghostTag;

  // combine posts and pages and sort result by date
  const postsAndPages = allGhostPost.edges.concat(allGhostPage.edges).sort((a: any, b: any) => {
    const bDate = new Date(b.node.published_at);
    const aDate = new Date(a.node.published_at);

    return Number(bDate) - Number(aDate);
  });

  // show the primary tag of interest
  postsAndPages.forEach((post: any) => {
    post.node.tags[0].name = name;
  });

  const isFeaturedTag = !!feature_image;

  const featuredColor = allFeaturedTagColorsJson.edges.find(({ node }) => node.tag === slug);

  return (
    <>
      <MetaData data={data} location={location} />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div style={{ backgroundColor: featuredColor?.node.color }} className={style.hero}>
          <div className="container">
            {isFeaturedTag ? (
              <>
                <small className="d-none d-md-block">You're reading</small>
                <h1>{name}</h1>
                <p>
                  {description
                    ? description
                    : `Thinking about ${name}? Explore our resources today to start taking steps toward a tomorrow that’s different.`}
                </p>
              </>
            ) : (
              <>
                <h1>{name}</h1>
                {description && <p>{description}</p>}
                <p>
                  <PostsIcon /> {allGhostPost.totalCount + allGhostPage.totalCount}{' '}
                  {allGhostPost.totalCount + allGhostPage.totalCount === 1 ? 'post' : 'posts'}
                </p>
              </>
            )}
          </div>
        </div>
        <div className={style.posts}>
          <div className="container">
            <PostGrid posts={postsAndPages} />
            <Pagination pageContext={pageContext} className={style.pagination} />
          </div>
        </div>
        <SignUpBanner />
      </Layout>
    </>
  );
};

export default Tag;

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
            profile_image
          }
          html
          tags {
            name
            slug
          }
        }
      }
    }
    allGhostPage(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
            profile_image
          }
          tags {
            name
            slug
          }
        }
      }
    }
    allFeaturedTagColorsJson {
      edges {
        node {
          color
          tag
        }
      }
    }
  }
`;
