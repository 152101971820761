import React from 'react';

import { lazyLoading } from '../../utils/lazyLoading';

import style from './Avatar.scss';

import cn from 'classnames';

interface IAvatarProps {
  className?: string;
  profile_image: string;
  name: string;
}

export const Avatar = (props: IAvatarProps) => {
  const { className, profile_image, name = '' } = props;

  React.useEffect(() => {
    lazyLoading();
  }, []);

  return (
    <div className={cn(className, style.profileImage)}>
      <div
        className={style.image}
        style={
          profile_image
            ? {
                backgroundImage: `url(${profile_image})`,
              }
            : undefined
        }
      ></div>
      <h2>{name.slice(0, 1)}</h2>
    </div>
  );
};
