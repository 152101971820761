import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const SuccessIcon = (props: IIconProps) => {
  const { width = 26, className } = props;
  const height = (width * 26) / 26;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(1 .985)" strokeWidth="2" fill="none" fillRule="evenodd">
        <circle stroke="#111" cx="12" cy="12" r="12" />
        <path stroke="#111" d="M6 11l4.305 4.305L17.609 8" />
      </g>
    </svg>
  );
};

export default React.memo(SuccessIcon);
