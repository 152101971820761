import React from 'react';

import CheckmarkIcon from '../../images/Checkmark';
import SuccessMark from '../../images/SuccessMark';
import SignUpForm from '../Form/SignUp';

import style from './SignUp.scss';

import cn from 'classnames';

interface ISignUpBannerProps {
  className?: string;
  btnText?: string;
}

const SignUpBanner = ({ className, btnText }: ISignUpBannerProps) => {
  return (
    <div className={cn(style.signUpBanner, className)}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-lg-4">
            <h2>Want to earn an extra $100 this week?</h2>
            <p>Get weekly tips on ways to earn extra cash from TSR.</p>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ul>
              <li>
                <CheckmarkIcon />
                <p>Save on everyday expenses</p>
              </li>
              <li>
                <CheckmarkIcon />
                <p>Find money-making opportunities</p>
              </li>
              <li>
                <CheckmarkIcon />
                <p>Fast-track your savings</p>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-4">
            <SignUpForm className={style.form} btnText={btnText}>
              {/* Render children on subscribed */}
              <div className={style.successMessage}>
                <SuccessMark />
                <p>
                  Success! Now check your email <br />
                  to confirm your subscription.
                </p>
              </div>
            </SignUpForm>
            <p>
              By clicking on 'subscribe,' you agree to our{' '}
              <a href="/terms-and-conditions/">Terms</a> and <a href="/privacy-policy/">Policy</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpBanner;
