import React from 'react';

import style from './Pagination.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

interface IPaginationProps {
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    humanPageNumber: number;
    numberOfPages: number;
  };
  className?: string;
}

class Pagination extends React.PureComponent<IPaginationProps> {
  render() {
    const {
      previousPagePath,
      nextPagePath,
      humanPageNumber,
      numberOfPages,
    } = this.props.pageContext;

    if (numberOfPages === 1) {
      return null;
    }

    // Build pagination

    let pathPrefix = '';
    if (previousPagePath) {
      pathPrefix = previousPagePath.replace(/\/page\/\d+\/?$/, '');
    } else {
      pathPrefix = nextPagePath.replace(/\/page\/\d+\/?$/, '');
    }

    pathPrefix = `${pathPrefix}/page`;

    const pageLinks = [];

    const createLink = (pageNumber: number) =>
      pageNumber === humanPageNumber ? (
        <span className={style.active}>{pageNumber}</span>
      ) : (
        <Link
          to={pageNumber > 1 ? `${pathPrefix}/${pageNumber}` : pathPrefix.replace(/\/page$/, '')}
          rel="prev"
        >
          {pageNumber}
        </Link>
      );

    // Do not show ellipsis if there's less than 9 pages.
    if (numberOfPages < 9) {
      for (let i = 1; i < numberOfPages + 1; i += 1) {
        pageLinks.push(createLink(i));
      }
    } else {
      // Always show the first page link.
      pageLinks.push(createLink(1));

      if (humanPageNumber < 5) {
        for (let i = 2; i < Math.max(6, humanPageNumber + 2); i += 1) {
          pageLinks.push(createLink(i));
        }
        pageLinks.push(<span className={style.link}>...</span>);
      } else if (humanPageNumber >= numberOfPages - 5) {
        pageLinks.push(<span className={style.link}>...</span>);
        for (let i = Math.min(humanPageNumber - 1, numberOfPages - 4); i < numberOfPages; i += 1) {
          pageLinks.push(createLink(i));
        }
      } else {
        pageLinks.push(<span className={style.link}>...</span>);
        pageLinks.push(createLink(humanPageNumber - 1));
        pageLinks.push(createLink(humanPageNumber));
        pageLinks.push(createLink(humanPageNumber + 1));
        pageLinks.push(<span className={style.link}>...</span>);
      }

      // Always show the last page link.
      pageLinks.push(createLink(numberOfPages));
    }

    return (
      <nav className={cn(this.props.className, style.pagination)} role="navigation">
        <div className={style.pages}>
          {humanPageNumber === 1 ? null : (
            <Link className={style.arrow} to={previousPagePath} rel="prev">
              Prev
            </Link>
          )}
          {pageLinks.map((item, key) => React.cloneElement(item, { key }))}
          {humanPageNumber === numberOfPages ? null : (
            <Link className={style.arrow} to={nextPagePath} rel="next">
              Next
            </Link>
          )}
        </div>
      </nav>
    );
  }
}

export default Pagination;
