import React from 'react';

interface IIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const PostsIcon = (props: IIconProps) => {
  const { className, width = 24, height = 24 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M18 3.5L13.7535 7.74651C13.2869 8.21308 13.0536 8.44636 12.864 8.71022C12.6956 8.94448 12.553 9.19626 12.4388 9.46119C12.3101 9.7596 12.2301 10.0797 12.0701 10.7198L11.5 13L13.7802 12.4299L13.7802 12.4299C14.4204 12.2699 14.7404 12.1899 15.0388 12.0612C15.3037 11.947 15.5555 11.8044 15.7898 11.636C16.0536 11.4464 16.2869 11.2131 16.7535 10.7465L21 6.5C21.0797 6.42035 21.1195 6.38052 21.1515 6.34526C21.8449 5.58244 21.8449 4.41756 21.1515 3.65474C21.1195 3.61948 21.0797 3.57966 21 3.50003L21 3.5C20.9203 3.42035 20.8805 3.38052 20.8453 3.34847C20.0824 2.65508 18.9176 2.65508 18.1547 3.34847C18.1195 3.38052 18.0797 3.42035 18 3.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M17.5 4V4C17.25 5.75 18.75 7.25 20.5 7V7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M12.5 2H12H10.5C7.69974 2 6.29961 2 5.23005 2.54497C4.28924 3.02433 3.52433 3.78924 3.04497 4.73005C2.5 5.79961 2.5 7.19974 2.5 10V14C2.5 16.8003 2.5 18.2004 3.04497 19.27C3.52433 20.2108 4.28924 20.9757 5.23005 21.455C6.29961 22 7.69974 22 10.5 22H14.5C17.3003 22 18.7004 22 19.77 21.455C20.7108 20.9757 21.4757 20.2108 21.955 19.27C22.5 18.2004 22.5 16.8003 22.5 14V12.5V12"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default React.memo(PostsIcon);
