import React from 'react';

import { Avatar } from '../../Avatar/Avatar';
import { IPostCard } from '../Card';

import style from './PostStats.scss';

import { readingTime } from '@tryghost/helpers';
import { formatPublishedAtDate } from 'utils/helpers';

interface IProps {
  publishedAt: string;
  author?: {
    slug: string;
    name: string;
    profile_image: string;
    bio: string;
  };
  post?: IPostCard;
  isReadingTimeShown?: boolean;
}

export const PostStats: React.FC<IProps> = ({ publishedAt, author, post, isReadingTimeShown }) => {
  return (
    <p className={style.wrapper}>
      {author && (
        <>
          <Avatar
            className={style.profileImage}
            profile_image={author.profile_image}
            name={author.name}
          />
          <span className={style.author}>{author.name}</span>
          <span className={style.dot}>&#183;</span>
        </>
      )}
      {isReadingTimeShown && readingTime(post)}
      <span className={style.dot}>&#183;</span>
      {publishedAt ? <span>{formatPublishedAtDate(publishedAt)}</span> : null}
    </p>
  );
};

export default PostStats;
