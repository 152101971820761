import React from 'react';

import { IPostCard, PostCard } from '../Card';

import style from './PostGrid.scss';

import cn from 'classnames';

interface IProps {
  className?: string;
  posts: {
    node: IPostCard;
  }[];
  isExcerptShown?: boolean;
}

export const PostGrid = ({ className, posts, isExcerptShown }: IProps) => (
  <div className={cn(className, style.wrapper)}>
    {posts.map(({ node }, index) => (
      <PostCard key={index} post={node} isExcerptShown={isExcerptShown} isLazy={index > 2} />
    ))}
  </div>
);
